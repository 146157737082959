import React, { useEffect, useState } from 'react';
import "./styles/Answer.css"

const Answer = ({ text, answer, updateAnswerState }) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        // text = `\\chemname{\\chemfig{*6(=-=-=-)}}{Benzene}`;
        const isLatex = text.includes('\\');

        if (isLatex) {
            const fetchRenderedImage = async () => {
                try {
                    const encodedText = encodeURIComponent(text);
                    const imageUrl = `https://latex.codecogs.com/svg.latex?${encodedText}`;
                    setImageSrc(imageUrl);
                } catch (error) {
                    console.error('Error rendering LaTeX:', error);
                }
            };

            fetchRenderedImage();
        } else {
            setImageSrc(''); // Reset image source if it's plain text
        }
    }, [text]);

    const handleClick = () => {
        if (!answer.clicked) {
            if (answer.isCorrect) {
                updateAnswerState(true);
            } else {
                updateAnswerState(false);
            }
        }
    };

    return (
        <button
            onClick={handleClick}
            className={`w-full text-center p-4 my-2 rounded-lg shadow-md font-medium text-lg ${answer.clicked ? (answer.isCorrect ? 'bg-green-500 text-white text-2xl' : 'bg-red-500 text-white') : 'bg-gray-200 text-gray-700 '}`}
        >
            <div className={`button-content`}>
                {imageSrc ? <img src={imageSrc} alt="Rendered LaTeX" style={{ maxWidth: '15%', maxHeight: '15%' }} /> : text}
            </div>

        </button>
    );
};

export default Answer;
