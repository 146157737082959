import React, { useState, useEffect } from 'react';
import Answer from './Answer';

const Question = ({ question }) => {
    const [clickedCorrect, setClickedCorrect] = useState(0);
    const [clickedWrong, setClickedWrong] = useState(0);
    const [shuffledAnswers, setShuffledAnswers] = useState([]);

    const correctAnswers = question.answers.filter(answer => answer.isCorrect).length;
    // Function to shuffle answers
    const shuffleAnswers = (answers) => {
        let shuffled = [...answers];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const updateAnswerState = (isCorrect, answerText) => {
        // Find the answer in the original array
        const answerIndex = question.answers.findIndex(a => a.text === answerText);

        // Update state based on the original answer's correctness
        if (question.answers[answerIndex].isCorrect) {
            setClickedCorrect(clickedCorrect + 1);
        } else {
            setClickedWrong(clickedWrong + 1);
        }
        console.log(`${clickedCorrect} clicked correct, ${clickedWrong} clicked wrong, ${question.answers[answerIndex].text}`)
        question.answers[answerIndex].clicked = true;
    };

    useEffect(() => {
        setShuffledAnswers(shuffleAnswers(question.answers));
        setClickedCorrect(0);
        setClickedWrong(0);
        // Reset the clicked state of all answers when the question changes
        question.answers.forEach(answer => answer.clicked = false);
    }, [question]);

    return (
        <div className='flex flex-col items-center w-full max-w-screen-xl p-4 m-2 border-2 border-gray-500 rounded-xl shadow-md bg-white' style={{ maxWidth: '1400px', minWidth: '1400px' }}>
            <div className="flex justify-between w-full">
                <h2 className='w-2/3 text-3xl mb-4 text-question-800 font-bold'>{question.id}. {question.text}</h2>
                <span className='text-3xl justify-self-end text-green-500'>PSO: {correctAnswers - clickedCorrect}</span>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 w-full'>
                {shuffledAnswers.map((answer, index) => (
                    <div key={index} className='w-full'>
                        <Answer text={answer.text} answer={answer} updateAnswerState={(isCorrect) => updateAnswerState(isCorrect, answer.text)}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Question;
